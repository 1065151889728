<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Atlasposting Users") }}
          <span class="d-block text-muted pt-2 font-size-sm">User management</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-input v-model="filter" placeholder="Search" class="min-w-300px mb-0 mr-0" :debounce="300"></euro-input>
        <b-dropdown id="dropdown-right" right :text="$t('Add User')" variant="btn btn-light-primary font-weight-bolder">
          <template #button-content>
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
            </span>
            {{ $t("Add User") }}
          </template>
          <b-dropdown-item @click="createEuropostingPhysicalPerson">{{ $t("Add Physical Person") }}</b-dropdown-item>
          <b-dropdown-item @click="createEuropostingCompany">{{ $t("Add Legal Person") }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="card-body">
      <datatable ref="usersTable" :table-props="tableProps" :total="total" :options.sync="tableOptions"
        :per-page-options="perPageOptions">
        <template #[`cell.avatar`]="{ item }">
          <div class="d-flex align-items-center">
            <Avatar :avatar-image="item?.avatar" :avatar-text="getInitials(item.first_name, item.last_name)" />
            <div class="ml-3">
              <div class="font-weight-bold" :style="{ wordBreak: 'break-word' }">
                {{ `${item?.first_name} ${item?.last_name}` }}
              </div>
              <div class="text-muted font-size-xs">{{ item.email }}</div>
            </div>
          </div>
        </template>
        <template #[`cell.company_name`]="{ item }">
          <span class="font-weight-bold">{{ item.company_name }}</span>
        </template>
        <template #[`cell.role`]="{ item }">
          <span class="font-weight-bold">{{ getUserRole(item) }}</span>
        </template>
        <template #[`cell.is_active`]="{ item }">
          <span class="label font-weight-bold label-inline text-capitalize"
            :class="{ 'label-light-success': item.is_active, 'label-light-danger': !item.is_active }">
            {{ item.is_active ? $t("ACTIVE").toLowerCase() : $t("NOT ACTIVE").toLowerCase() }}
          </span>
        </template>
        <template #[`cell.date_joined`]="{ item }">
          <span class="font-weight-bolder text-muted">{{ DateService.format(item.date_joined, dateFormat) }}</span>
        </template>
        <template #[`cell.is_invite_accepted`]="{ item }">
          <span class="label font-weight-bold label-inline text-capitalize"
            :class="{ 'label-light-success': item.is_invite_accepted, 'label-light-danger': !item.is_invite_accepted }">
            {{ item.is_active ? $t("ACTIVE").toLowerCase() : $t("NOT ACTIVE").toLowerCase() }}
            {{
              item.is_invite_accepted
              ? $t("ACCEPTED").toLowerCase()
              : $t("NOT ACCEPTED").toLowerCase()
            }}
          </span>
        </template>
        <template #[`cell.actions`]="{ item }">
          <div class="text-nowrap">
            <btn-icon :icon="item.is_active ? icons.ban : icons.check"
              :tooltip="item.is_active ? $t('Disable User') : $t('Enable User')" size="xs" @click="toggleUser(item)">
            </btn-icon>
            <btn-icon :icon="icons.notify" :tooltip="$t('Notifications settings')" class="ml-3" size="xs"
              @click="openNotificationsSettings(item)">
            </btn-icon>
            <btn-icon :icon="icons.sendMail" :tooltip="item.is_invite_accepted ? $t('Send password reset email') : $t('Send new invite email')
              " class="mx-3" size="xs" @click="sendNewInviteMail(item)">
            </btn-icon>
            <btn-icon :icon="icons.lineAwesome.physicalPerson" :tooltip="$t('Go to physical person profile')" size="xs"
              @click="goToPhysicalPerson(item)">
            </btn-icon>
          </div>
        </template>
      </datatable>
      <NotificationsSettingsModal v-bind="editNotificationsModal" :user-name="userName"
        @close="closeNotificationsSettings" @close-refresh="closeNotificationsSettings"></NotificationsSettingsModal>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import UsersService from "@/core/services/users.service";
import DateService from "@/core/services/date.service";
import InvitationService from "@/core/services/invitation/invitation.service";
import { backendErrorSwal, toggleUserSwal } from "@/core/helpers/swal";
import NotificationsSettingsModal from "@/view/components/modals/NotificationsSettingsModal.vue";
import Avatar from "@/view/components/avatars/Avatar.vue";
import { mapGetters } from "vuex";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import { successToast } from '@/core/helpers';


export default {
  components: {
    NotificationsSettingsModal,
    Avatar,
  },

  data() {
    return {
      editNotificationsModal: {},
      userName: "",
      fields: [
        { label: this.$t("User"), key: "avatar" },
        { label: this.$t("Company"), key: "company_name", sortable: true },
        { label: this.$t("Company role"), key: "role", sortable: true },
        { label: this.$t("ACTIVE"), key: "is_active", sortable: true },
        { label: this.$t("Date Joined"), key: "date_joined", sortable: true },
        { label: this.$t("Invite"), key: "is_invite_accepted", sortable: true },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      filter: "",
      icons,
      total: 0,
      perPageOptions: perPageOptions,
      tableOptions: tableOptions,
      DateService: DateService,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat", "isApStaff"]),

    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        responsive: true,
        filter: this.filter,
      };
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Users") },
      { title: this.$t("Atlasposting Users") },
      { title: this.$t("Manage") },
    ]);
    if(this.$route.params.filter) {
      this.filter= this.$route.params.filter
    }
  },

  methods: {
    closeNotificationsSettings() {
      this.editNotificationsModal = {};
      this.userName = "";
      this.$bvModal.hide("notifications-settings-modal");
      const table = this.$refs.usersTable;
      if (table) table.refresh();
    },

    openNotificationsSettings(user) {
      const { notificationsettings_set, id, first_name, last_name } = user;
      if (!notificationsettings_set) backendErrorSwal();
      this.userName = `${first_name} ${last_name}`;

      this.editNotificationsModal = { entity: id, notificationsettings_set };
      this.$bvModal.show("notifications-settings-modal");
    },

    async toggleUser(item) {
      const res = await toggleUserSwal(item.is_active);
      if (res.isConfirmed) {
        try {
          await UsersService.editUser(item.id, {
            is_active: !item.is_active,
          });
          successToast();
          item.is_active = !item.is_active
        } catch (err) {
          backendErrorSwal(err, err?.response?.detail);
        }
      }
    },

    getInitials(first, last) {
      return `${first ? first.toUpperCase()[0] : ""}${last ? last.toUpperCase()[0] : ""}`;
    },

    itemProvider(ctx, callback) {
      UsersService.getEuropostingUsers({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "date_joined",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        fields: 'id,persona,avatar,first_name,last_name,email,company_name,is_active,date_joined,is_invite_accepted,notificationsettings_set,managedcompany_set'
      }).then(res => {
        this.total = res.count;
        callback(res.results);
      }).catch(() => callback([]));

      return null;
    },

    sendNewInviteMail(user) {
      InvitationService.resendInvitation(user.email).then(() => {
        successToast(this.$t("Mail sent"));
      });
    },

    goToPhysicalPerson(user) {
      this.$router.push({ name: "detail-physical-person", params: { userID: user.persona } });
    },

    createEuropostingPhysicalPerson() {
      this.$router.push({ name: "administration-users-create", params: { type: "physical-person" } });
    },
    createEuropostingCompany() {
      this.$router.push({ name: "administration-users-create", params: { type: "company" } });
    },
    getUserRole(user) {
      // gli utenti possono avere solo una company
      let managedCompany = user.managedcompany_set[0];
      switch (managedCompany.permission_level) {
        case 0: return "Admin";
        case 1: return "After Sale";
        default: return "employee"
      }
    }
  },
};
</script>

<style scoped>
.btn.btn-outline-light {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover i::before {
  color: var(--primary);
}
</style>
